/* VARIABLES */
:root {

    /* Colors */
    --white: #FFF;
    --black: #000;
}

/* HEADINGS */
h1 {
    color: var(--black);
    font-size: 2.55em;
    font-weight: 900;
}

h2 {
    font-size: 2em;
    font-weight: 700;
    color: var(--black);
}

h4 {
    font-size: 1.4em;
    color: var(--black);
}

/* HOME BANNER CSS */
.home-banner {
    width: 100%;
    min-height: 540px;
    position: relative;
    background: url('../public/assets/images/piano-student1.jpg') no-repeat center center/cover;
    animation: changeBackground 40s infinite;
}

@keyframes changeBackground {
    0% {
        background-image: url('../public/assets/images/piano-student2.jpg');
    }

    20% {
        background-image: url('../public/assets/images/piano-student3.jpg');
    }

    40% {
        background-image: url('../public/assets/images/piano-student4.jpg');
    }

    60% {
        background-image: url('../public/assets/images/piano-student5.jpg');
    }

    80% {
        background-image: url('../public/assets/images/piano-student6.jpg');
    }

    100% {
        background-image: url('../public/assets/images/recital.jpg');
    }
}

.home-banner .inner-content {
    position: absolute;
    min-height: 540px;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.55);
}

.home-banner .inner-content h1 {
    color: var(--white) !important;
}

/* ABOUT SECTION CSS  */
#aboutSection {
    width: 100%;
    margin-top: 6rem;
    min-height: 400px;
}

#aboutSection .container {
    border-radius: 10px;
}

.contact-from {
    border-radius: 10px;
}

/* DISCOVER PIANO  SECTION CSS  */

.discoverpianosection {
    width: 100%;
    margin-top: 6rem;
}

/* UTILITIES CLASSES */

.bg-ligh-gray {
    background-color: #f5f5f5;
}

.inst-card,
.inst-card img {
    border-radius: 8px;
}

.c-img-card {
    border-radius: 6px;
    border: 2px solid #eeee;
}

/* Mobile Responsive */

@media (max-width: 768px) {

    /* HEADINGS */
    h1 {
        font-size: 2em !important;
        font-weight: 900;
    }

    h2 {
        font-size: 1.88em !important;
        font-weight: 700;
    }

    h4 {
        font-size: 1.3em;
    }

    p {
        font-size: 14px;
    }
    main {
        width: 100%;
    }

    .home-banner,
    .home-banner .inner-content {
        min-height: 300px;
    }

    #aboutSection,
    .discoverpianosection{
        margin-top: 2rem;
    }
    #aboutSection .right-content {
        margin-top: 1rem;
    }
    #aboutSection 
    img {
        width: 100%;
        height: 360px;
    }

    .discoverpianosection
    .header-content {
        width: 100% !important;
    }

    .discoverpianosection
    .body-content img {
        width: 100%;
    }
    .contact-from {
        width: 100% !important;
    }

    .c-img-card{
        height: 240px !important;
    }

    .sm-reverse-wrap {
        flex-wrap: wrap-reverse;
    }
}